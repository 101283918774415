import { FC, ReactNode } from "react";
import Arrow from '../../../../@assets/icons/arrow.png';
import './slider-navigation.scss';

interface Props {
    currentSlide: number,
    setCurrentSlide: (value: any) => void,
    slides: ReactNode[],
    isFirstSwipe: boolean,
};

export const SliderNavigation: FC<Props> = ({ currentSlide, setCurrentSlide, slides, isFirstSwipe }) => {
    const nextSection = () => {
        setCurrentSlide((prevSection: number) => (prevSection + 1) % slides.length);
    };

    const prevSection = () => {
        setCurrentSlide((prevSection: number) => (prevSection - 1 + slides.length) % slides.length);
    };

    return (
        <div className="slider-navigation-wrapper">
            <div className="buttons-wrapper">
                <div className={`nav-button ${currentSlide !== 0 ? '' : 'disabled'}`} onClick={prevSection}>
                    <img alt='<-' className="inverted-black-img rotated" src={Arrow} />
                </div>
                <div className="pointer-events-none grow"></div>
                <div className={`nav-button ${currentSlide !== slides.length - 1 ? '' : 'disabled'} ${ isFirstSwipe ? 'pulsing' : ''}`} onClick={nextSection}>
                    <img className='inverted-black-img' alt='->' src={Arrow} />
                </div>

            </div>
            <div className="bullets">
                {
                    slides.map((s, i) => (
                        <div
                            onClick={() => setCurrentSlide(i)}
                            key={i}
                            className={`bullet ${i === currentSlide ? 'selected-bullet' : ''}`}
                        />
                    ))
                }
            </div>
        </div>
    );
};