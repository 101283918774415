import { FC } from "react";
import { RightSideWrapper } from "./right-side-wrapper/right-side-wrapper";
import { WaitListComponent } from "./waitlist/waitlist";
import './main-section.scss';

export const MainSection: FC = () => {
    return (
        <section className="main-section">
            <section className="left-side">
                <WaitListComponent />
            </section>
            <section className="right-side">
                <RightSideWrapper />
            </section>
        </section>
    )
}