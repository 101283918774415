import { FC } from 'react';
import Logo from '../../@assets/images/blue-logo.png';
import './error-page.scss';

export const ErrorPage: FC = () => {
    return (
        <div className='error-page-wrapper'>
                <h1>404 Page Not Found</h1>
                <p>We're sorry, but the page you were looking for doesn't exist.</p>
                <img src={Logo} alt="Logo" />
        </div>
    );
}
