import { SlideWrapper } from "../../slide-wrapper/slide-wrapper";
import './wellcome-slide.scss';

export const WellcomeSlide = () => {
    return (
        <SlideWrapper>
            <div className="wellcome-slide-wrapper">
                <div className="wellcome-message">
                    <div className="title">Welcome to <span>TACS</span></div>
                    <div className="sub-wrapper">
                        <div className="left">
                            <span className="accent-2">TAC Shield </span> is more than just a service - it's your partner in understanding what exactly you're agreeing to.
                            We've designed our service to spot any possible threats and raise the alarm on any red flags.
                            This is how we protect you from any hidden pitfalls that might be hidden behind complicated wording.
                            You're no longer just blindly accepting terms and conditions.
                            It's all about giving you the power of understanding them.
                            We break down the contents of any document into a simple, easy-to-understand analysis, ensuring you're not just aware of what you're agreeing to online.
                            Knowledge is a superpower, and TAC Shield is your personal sidekick.
                            <p>
                                We are here to empower your decisions,
                                <span className="accent-2"> not replace legal counsel </span> - it simply
                                <span className="color-0"> illuminates potential risks </span> within voluminous documents,
                                letting you focus on what matters most.
                            </p>
                        </div>
                        <div className="right">
                            <div className="card-wrapper">
                                <div className="backdrop"></div>
                                <div className="card">
                                    <div className="right-side-title">About us</div>
                                    <ul>
                                        <li><span className='accent-1'>Our mission</span> is to harness the full potential of AI to empower you in safeguarding yourself from unwelcome circumstances. </li>
                                        <li><span className='accent-1'>We believe</span> that knowledge is a superpower. TAC Shield equips you with the knowledge you need to navigate the digital landscape confidently. </li>
                                        <li><span className='accent-1'>Our Goal</span> is to become the go-to platform for individuals seeking clarity and protection in the digital world. </li>
                                    </ul>
                                    <div className="more">
                                        <p>Want to learn more?</p>
                                        <a href="#contact">Get in touch »</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert-wrapper">
                    <div className="alert">
                        <p> No more passive acceptance of conditions.</p>
                        <p>It's time to <strong> KNOW </strong> what you're agreeing to with <strong> TAC Shield</strong>.</p>
                    </div>
                </div>
            </div>

        </SlideWrapper>
    );
};