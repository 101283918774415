import { FC } from "react";
import { MainSection } from "./main-section/main-section";
import { ContactSection } from "./contact-section/contact-section";
// import { TimelineSection } from "./timeline-section/timeline-section";

export const MainPage: FC = () => {
    return (
        <>
            <MainSection />
            {/* <TimelineSection /> */}
            <ContactSection />
        </>
    )
}