import { FC, useState } from 'react';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../../@services/firebase-config';
import './waitlist.scss';

export const WaitListComponent: FC = () => {
    const [joinWaitListEmail, setJoinWaitListEmail] = useState('');
    const [waitListFormMessage, setWaitListFormMessage] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);

    const subscribersCollection = collection(db, "subscribers");

    const handleJoinTheWaitlist = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setWaitListFormMessage('');
            await validateEmail(joinWaitListEmail);
            const subscribedAt = Date.now();
            const lastChange = Date.now();
            const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const email = joinWaitListEmail;
            const subscribed = true;
            const subsInfo = { subscribedAt, lastChange, country, email, subscribed };
            const res = await addDoc(subscribersCollection, subsInfo);
            if (res.type === 'document') {
                setJoinWaitListEmail("");
                setIsAgreed(false);
                setWaitListFormMessage(`${joinWaitListEmail} Subscribed!`);
            }
        } catch (error: any) {
            setWaitListFormMessage(error.message);
            setIsAgreed(false);
        }
    }

    const validateEmail = async (email: string) => {
        if (!isAgreed) throw new Error("Please agree to the terms");
        if (!email) throw new Error("Email not Valid");
        if (email.length < 5) throw new Error("Too short Email");
        if (!email.includes("@")) throw new Error("Email not Valid");

        const querySnapshot = await getDocs(query(subscribersCollection, where("email", "==", email)));
        if (!querySnapshot.empty) throw new Error("The Email is Already Subscribed");

        return true;
    };

    return (
        <div className="waitlist-wrapper">
            <div className='waitlist' id='waitlist'>
                <div className="title">Subscribe</div>
                <div className="sub-title">
                    Stay ahead of the curve with exclusive insights, tips, and updates.
                    Be the first to know about our latest features and how you can make more informed agreement decisions.

                </div>
                <form onSubmit={handleJoinTheWaitlist}>
                    <div className="form-wrapper">
                        <input
                            value={joinWaitListEmail}
                            onChange={(event) => setJoinWaitListEmail(event.target.value)}
                            type="email"
                            autoComplete="email"
                            required
                            placeholder="Enter your email"
                        />
                        <button className='button' type="submit">Subscribe</button>
                    </div>
                    <div className="checkbox-wrapper">
                        <input
                            type="checkbox"
                            required
                            checked={isAgreed}
                            onChange={() => setIsAgreed(!isAgreed)}
                        />
                        <label>
                            I agree to the <a href="/terms" target='_blank'>Terms of Service.</a>
                        </label>
                    </div>
                </form>
                <p className='join-message'>{waitListFormMessage || ''} </p>
            </div>
        </div>
    );
};
