
import { useNavigate } from 'react-router-dom';
import './terms-page.scss';
import { FC } from 'react';

export const TermsPage: FC = () => {
    const navigate  = useNavigate();
    const goToHome = () => {
        window.scroll({ top: 0});
        navigate('/', {replace: true });
    }
    return (
        <div className='terms-page-wrapper'>
            <div className="terms">
            <h2>Terms of Service</h2>
                <p>
                    By accessing or using TAC Shield, you are agreeing to be bound by the following terms and conditions. If you disagree with any part of these terms, you may not access the service.
                </p>
                <ul>
                    <li>TAC Shield is provided "as is" and without warranty of any kind. Any reliance you place on such information is therefore strictly at your own risk.</li>
                    <li>In no event will we be liable for any loss or damage arising from loss of data or profits arising out of, or in connection with, the use of this app.</li>
                    <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</li>
                    <li>We reserve the right to modify or terminate the service for any reason, without notice, at any time.</li>
                </ul>
                
                <h2>Privacy Policy</h2>
                <p>
                    At TAC Shield, one of our main priorities is the privacy of our users. This Privacy Policy contains types of information that is collected and recorded by TAC Shield and how we use it.
                </p>
                <ul>
                    <li>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent.</li>
                    <li>We only retain collected information for as long as necessary to provide you with your requested service. The data we store will be protected within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.</li>
                    <li>Our app may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</li>
                    <li>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</li>
                </ul>
            </div>
                <div onClick={() => goToHome()} className="go-back">← Back</div>

        </div>
    );
}
