import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDB_xJkUV_MdoX-_DfbM9bI4uC036rHJeM",
  authDomain: "tacs-landing.firebaseapp.com",
  projectId: "tacs-landing",
  storageBucket: "tacs-landing.appspot.com",
  messagingSenderId: "161192312170",
  appId: "1:161192312170:web:f75f51650cccbfb4edb913",
  measurementId: "G-ESMNC1NRMX",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);