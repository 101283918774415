import { SlideWrapper } from "../../slide-wrapper/slide-wrapper";

import Image1 from '../../../../../@assets/images/illustrations/ill1-dark.png';
import Image2 from '../../../../../@assets/images/illustrations/ill2-dark.png'
import Image3 from '../../../../../@assets/images/illustrations/ill3-dark.png'

import './features-slide.scss';

const illustrations = [
    {
        title: 'Risk Assessment',
        text: `
            Every contract carries potential risks.
            Our software uses sophisticated algorithms to evaluate and highlight the dangers that are specific to your context and preferences.
            It provides personalized insights, helping you to navigate your contracts with confidence.`,
        image: Image1,
    },
    {
        title: 'Contract Analysis',
        text: `
            With the power of AI, out tool goes deep into the details, analyzes carefully every part of your contract, and delivers a comprehensive summary of the conditions.
            It highlighting possible risks and responsibilities, giving you the ability to make informed choices.`,
        image: Image2,
        disabled: false,
    },
    {
        title: 'Legal Language Translation',
        text: `
            Don’t be overwhelmed by legal terminology.
            Our tool breaks down intricate legal language into easily understandable wording.
            It offers real-time clarifications, helping you to understand what you're consenting to.`,
        image: Image3,
    },
];
export const FeaturesSlide = () => {
    return (
        <SlideWrapper>
            <div className="features-slide-wrapper">
                {
                    illustrations.map((card, i) =>
                        <div key={i} className='card-wrapper'>
                            <div className={`card ${card.disabled ? 'disabled' : ''}`} >
                                <img alt={`Illustration ${i}`} src={card.image}></img>
                                <div className="title">{card.title}</div>
                                <div className="text">{card.text}</div>
                            </div>
                            {!card.disabled || <div className="comming-soon">Comming Soon</div>}
                        </div>

                    )
                }
            </div>
        </SlideWrapper>
    );
};