import { FC, ReactNode } from "react"
import './layout-wrapper.scss';

import { HeaderComponent } from "./components/header/header";
import { Footer } from "./components/footer/footer";

export const LayoutWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <section className="layout-wrapper">
            <div className="bg-image"></div>
            <HeaderComponent />
            <div className="inner-wrapper">
                {children}
            </div>
            <Footer />
        </section>
    )
}
