import { FC } from "react";
import EmailIcon from '../../../../@assets/icons/envelope.png';
import LinkedInIcon from '../../../../@assets/icons/linkedin.png';
import './footer.scss';

export const Footer: FC = () => {
    return (
        <footer>
            <div className="footer-inner-wrapper">
                <div className="socials">
                    <a href='mailto:tac.shield.pro@gmail.com'>
                        <img className='inverted-black-img' alt='Email' src={EmailIcon}  />
                    </a>
                    <a rel="noopener noreferrer" target='_blank' href='https://www.linkedin.com/company/tac-shield'>
                        <img className='inverted-black-img' alt='LinkedIn' src={LinkedInIcon} />
                    </a>
                </div>
                <p className="info">&copy; 2023 TACS | All rights reserved | <a target='_blank' href='/terms' > Terms of Service </a></p>
            </div>
        </footer>
    );
};
