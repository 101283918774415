import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LayoutWrapper } from './@layouts/landing-layout/layout-wrapper';

import { MainPage } from './@pages/main-page/main-page';
import { ErrorPage } from './@pages/error-page/error-page';
import { TermsPage } from './@pages/terms-page/terms-page';
// import { AppPage } from './@pages/app-page/app-page';
// import { LoadingWrapper } from './@layouts/loading-wrapper/loading-wrapper';

export const App: FC = () => {
  return (
    // <LoadingWrapper>
      <BrowserRouter>
        <RouterWrapper />
      </BrowserRouter>
    // </LoadingWrapper>
  );
}

export const RouterWrapper: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<LayoutWrapper><MainPage /></LayoutWrapper>} />
      <Route path='/terms' element={<LayoutWrapper><TermsPage /></LayoutWrapper>} />
      {/* <Route path='/app' element={<LayoutWrapper><AppPage /></LayoutWrapper>} /> */}
      <Route path='*' element={<LayoutWrapper><ErrorPage /></LayoutWrapper>} />
    </Routes>
  );
}
