import { FC, useEffect, useState } from "react";
import Logo from '../../../../@assets/images/blue-logo.png';
// import { useLocation, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './header.scss';

export const HeaderComponent: FC = () => {
    const location = useLocation();
    // const navigate  = useNavigate();

    const [scrollY, setScrollY] = useState(0);
    useEffect(() => {
        window.addEventListener('scroll', () => setScrollY(window.scrollY));
    }, []);

    const blink = () => {
        const element = document.getElementById('waitlist');
        if (!element) return;
        element.classList.add('animate');
        setTimeout(() => element.classList.remove('animate'), 200);
    }

    // const goToApp = () => {
    //     navigate('/app');
    // }

    return (
        <header className={scrollY > 10 ? 'scrolled' : ''}>
            <div className="header-inner-wrapper">
                <div className="logo">
                    <a href='/'>
                        <img src={Logo} alt='Logo' />
                        <span className="logo-text">TACS</span>
                    </a>
                </div>
                {!location.pathname.includes('app') && (
                    <button className="button" onClick={() => blink()}>JOIN THE WAITLIST</button>
                )}
            </div>
        </header>
    );
};
