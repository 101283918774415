import { FC, useState } from "react";
import { sendForm } from 'emailjs-com';

import './contact-section.scss';

export const ContactSection: FC = () => {
    const [contactFormEmail, setContactFormEmail] = useState('');
    const [contactFormText, setContactFormText] = useState('');
    const [contactFormMessage, setContactFormMessage] = useState('');
    const [contactFormLoading, setContactFormLoading] = useState(false);

    const handleContactFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setContactFormLoading(true);
            sendForm('tacsield-service', 'template_kmvj60n', event.currentTarget, 'I6UbAK2PKhhAUOPNo')
                .then(() => {
                    setContactFormMessage('The Message was sent!');
                    setContactFormEmail('');
                    setContactFormText('');
                    setContactFormLoading(false);
                }, () => {
                    setContactFormLoading(false);
                    setContactFormMessage('Something Goes Wrong. Please Try again');
                });
        } catch (error) {
            setContactFormLoading(false);
            setContactFormMessage('Something Goes Wrong. Please Try again');
        }
    };
    return (
        <div className="contact-section-wrapper" id="contact">
            <h2 className="title">Contact Us</h2>
            <form onSubmit={handleContactFormSubmit}>
                <input
                    value={contactFormEmail}
                    onChange={(event) => setContactFormEmail(event.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Email address"
                />
                <textarea
                    value={contactFormText}
                    onChange={(event) => setContactFormText(event.target.value)}
                    id="message"
                    name="message"
                    required
                    placeholder="Your message"
                ></textarea>
                <button
                    disabled={contactFormLoading}
                    type="submit"
                    className="button"
                > Submit</button>
                <p className='message'>{contactFormMessage || ''} </p>
            </form>
        </div>
    )
}