import { FC, Fragment, useState } from "react";
import { SliderNavigation } from "../slider-navigation/slider-navigation";
import { useSwipeable } from "react-swipeable";
import './right-side-wrapper.scss';
import { WellcomeSlide } from "../slides/wellcome-slide/wellcome-slide";
import { FeaturesSlide } from "../slides/featrues-slide/features-slide";

const slides = [
    <WellcomeSlide />,
    <FeaturesSlide />
];

export const RightSideWrapper: FC = () => {
    const [currentSlide, _setCurrentSlide] = useState(0);
    const [isFirstSwipe, setIsFirstSwipe] = useState(true);

    const setCurrentSlide = (value: number) => {
        setIsFirstSwipe(false);
        return _setCurrentSlide(value);
    };

    const handlers = useSwipeable({
        onSwipedRight: () => currentSlide !== 0 ? setCurrentSlide(currentSlide - 1) : null,
        onSwipedLeft: () => currentSlide !== (slides.length - 1) ? setCurrentSlide(currentSlide + 1) : null,
        trackMouse: true,
    });

    return (
        <Fragment>
            <div className="right-side-wrapper" style={{ right: `${currentSlide * 100}%` }} {...handlers}>
            {slides.map((slide, index) => (
                    <Fragment key={index}>
                        {slide}
                    </Fragment>
                ))}
            </div>
            <SliderNavigation currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} slides={slides} isFirstSwipe={isFirstSwipe} />
        </Fragment>
    );
};